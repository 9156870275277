import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Popover } from 'react-bootstrap';
import ReactDateTime from 'react-datetime';
import { thisBinder, parseMomentDate, convertToISOString } from 'src/scripts/helpers.js';

class DateTimePicker extends Component {
  constructor() {
    super();
    this.state = {
      isCalendarHidden: true,
    };
    thisBinder(this, [
      'onCalendarChange',
      'onInputChange',
      'formatIncomingValueForDisplay',
      'parseValueUsingMoment',
      'toggleCalendarVisibility',
      'hideCalendar',
      'clear',
    ]);
  }

  onCalendarChange = (selectedMomentDate) => {
    const dateISOString = convertToISOString(selectedMomentDate);
    this.props.onChange(dateISOString);
  };

  onInputChange = (event) => {
    const manualStringInput = event.target.value;
    const momentDate = parseMomentDate(manualStringInput, this.props.dateFormatForDisplay);
    if (momentDate.isValid()) {
      const dateISOString = convertToISOString(momentDate);
      this.props.onChange(dateISOString);
    } else {
      // If the date is not valid, call the onChange prop function with the raw input value.
      this.props.onChange(manualStringInput);
    }
  };

  formatIncomingValueForDisplay = (untreatedIncomingValue) => {
    const momentDate = parseMomentDate(untreatedIncomingValue);
    if (momentDate.isValid()) {
      return momentDate.format(this.props.dateFormatForDisplay);
    }
    // If the date is not valid, return the raw incoming value.
    return untreatedIncomingValue;
  };

  parseValueUsingMoment = (incomingValue) => {
    const momentDate = parseMomentDate(incomingValue);
    if (momentDate.isValid()) {
      return momentDate;
    }
    // If not valid, return the raw incoming value.
    return incomingValue;
  };

  toggleCalendarVisibility = () => {
    this.setState((prevState) => ({ isCalendarHidden: !prevState.isCalendarHidden }));
  };

  hideCalendar = () => {
    this.setState({ isCalendarHidden: true });
  };

  clear = () => {
    this.props.onChange('');
  };

  render() {
    return (
      <div>
        <div
          ref="manualDateEntryWrapper"
          className={`date-time-picker-manual-entry ${this.props.className || ''}`}
        >
          <button
            ref="datePickerToggle"
            type="button"
            className={`
              date-time-picker-manual-entry__element
              date-time-picker-manual-entry__element--button
              date-time-picker-manual-entry__element--date-picker-toggle-button
              ${!this.state.isCalendarHidden ? 'active' : ''}
            `}
            onClick={this.toggleCalendarVisibility}
            disabled={this.props.disabled}
          >
            <i className="fa fa-calendar"></i>
          </button>
          <input
            ref="manualDateEntry"
            type="text"
            className={`
              date-time-picker-manual-entry__element
              date-time-picker-manual-entry__element--text-input
            `}
            value={this.formatIncomingValueForDisplay(this.props.value) || ''}
            disabled={this.props.disabled}
            onChange={this.onInputChange}
            placeholder={this.props.placeholder}
            data-pw="date-time-picker-input"
          />
          <button
            ref="clear"
            disabled={this.props.disabled}
            type="button"
            className="date-time-picker-manual-entry__element date-time-picker-manual-entry__element--button date-time-picker-manual-entry__element--clear-button"
            onClick={this.clear}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div
          ref="datePickerOutsideClickCaptureLayer"
          className={`
            date-time-picker__outside-click-capture-layer
            ${this.state.isCalendarHidden ? 'hidden' : ''}
          `}
          onClick={this.hideCalendar}
        />
        <div
          className="date-time-picker__wrapper"
          ref="reactDateTimeWrapper"
          hidden={this.state.isCalendarHidden}
        >
          <Popover id="dateTimePicker" placement="bottom">
            <ReactDateTime
              ref="reactDateTimePicker"
              input={false}
              value={this.parseValueUsingMoment(this.props.value)}
              onChange={this.onCalendarChange}
            />
          </Popover>
        </div>
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  dateFormatForDisplay: PropTypes.string,
  placeholder: PropTypes.string,
};

export const defaultProps = {
  disabled: false,
  className: '',
  value: '',
  dateFormatForDisplay: 'DD-MM-YYYY HH:mm',
  placeholder: '',
  onChange: () => {},
};

DateTimePicker.defaultProps = defaultProps;

export default DateTimePicker;
