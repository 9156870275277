import moment from 'moment';

export const thisBinder = (thisValue, arrayOfInternalMethodNames) => {
  arrayOfInternalMethodNames.forEach((methodName) => {
    if (thisValue[methodName]) {
      thisValue[methodName] = thisValue[methodName].bind(thisValue);
    } else {
      throw new Error(`Method ${methodName} does not exist on the context`);
    }
  });
};

// This function does not work with Nested Objects Null values.
export const createNewObjectNoNullValues = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== null));
};

/**
 * Parses a date string using the specified format.
 * @returns {moment.Moment} The parsed date as a moment object.
 */
export const parseMomentDate = (dateString, format = moment.ISO_8601) => {
  return moment(dateString, format, true);
};

/**
 * Converts a moment date to an ISO string format.
 */
export const convertToISOString = (momentDate) => {
  if (!momentDate.isValid()) {
    return null;
  }
  return momentDate.toISOString();
};
